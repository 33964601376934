import React from 'react';
import "./styles.css";
import moment from "moment";
import IosCreateOutline from "react-ionicons/lib/IosCreateOutline";

export default class SlotSessoes extends React.Component {
    render() {
        let count = 0;
        return <div className={"SlotSessoes noselect"}>
            <div className={"sessoes"}>
                {this.props.temas.map((tema) => {
                    let cor = tema.tema ? tema.tema.cor : null;
                    return <div className={"tema"}>
                        {tema.tema && <p className={"label"} style={{color: "#" + cor}}>{tema.tema.designacao}</p>}
                        {tema.categorias.map((categoria) => {
                            return <div>
                                {categoria.sessoes.map((sessao) => {
                                    count++;
                                    return <div className={"wrapperSessao"} key={"sessao_" + sessao.id}>
                                        <div className={"hora"}>
                                            {count === 1 && <span>{this.props.mostrarData && [<small>{moment(this.props.slot.inicio).format("D MMM").toUpperCase()}</small>, <br/>]}{moment(this.props.slot.inicio).format("H:mm")}</span>}
                                        </div>
                                        <div className={"sessao"} style={{borderColor: "#" + cor}} onClick={() => {
                                            this.props.mostrarDetalhesSessao && this.props.mostrarDetalhesSessao(sessao.id);
                                        }}>
                                            <div>
                                                <h4>{sessao.titulo}</h4>
                                                <p>{sessao.oradores.map(orador => orador.nome).join(" / ")}</p>
                                                {sessao.categoria && sessao.categoria.especial ? <p className={"categoria"}>{sessao.categoria.designacao}</p> : null}
                                            </div>
                                            {sessao.slot !== null && sessao.registado && this.props.onEdit && <div className={"edit"} onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.onEdit(sessao)
                                            }}>
                                                <IosCreateOutline color={"#aeaeae"}/>
                                            </div>}
                                        </div>
                                    </div>;
                                })}
                            </div>
                        })}
                    </div>
                })}
            </div>
        </div>;
    }
}
