import React from "react";
import Collapsible from 'react-collapsible';

import './styles.css';

import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import IosDownloadOutline from "react-ionicons/lib/IosDownloadOutline";
import Connection from "../../Connection";

class List extends React.Component {

    constructor(props) {
        super(props);

        this.key = btoa(+new Date).slice(-10, -2);
    }

    Item = (props) => {
        switch (props.appearance) {
            case "desafio":
                return <div className={"ListItem desafio" + (props.action ? " clickable" : "")} onClick={props.action}>
                    {props.icon && <span className={"icon"}>
                        {props.icon}
                    </span>}
                    <div>
                        <h5>{props.title}</h5>
                        <p>{props.text}</p>
                    </div>
                    <div>
                        {props.action && <IosArrowForward color={"#C4C4C4"}/>}
                        {props.points && props.points}
                    </div>
                </div>;
            case "download":
                return <div className={"ListItem download"}>
                    <h4>{props.title}</h4>
                    <IosDownloadOutline onClick={() => {
                        window.open(Connection.getBase() + "app/" + props.url)
                    }}/>
                </div>;
            case "session":
                return <div style={props.itemStyle} className={"ListItem session"}>
                    <div>
                        <h3>{props.title}</h3>
                        <h4>{props.speakers}</h4>
                    </div>
                    <p>{props.local}</p>
                </div>;
            case "accordion":
                return <Collapsible
                    classParentString={"ListItem accordion collapsible"}
                    open={!!props.expanded}
                    trigger={<div className={"header"}>
                        <h3>{props.title}</h3>
                        <IosArrowDown color={"#C4C4C4"} fontSize={32}/>
                    </div>}>
                    <div className={"innerTitle"}>{props.innerTitle}</div>
                    {props.content}
                </Collapsible>;
            case "input":
                return <div className={"ListItem input"}>
                    {props}
                </div>;
            case "toggle":
                return <div className={"ListItem toggle"}>
                    <label><input name={props.name} onChange={props.onChange} type={"checkbox"} defaultChecked={!!props.active} value={props.value}/><span>{props.label}</span></label>
                </div>;
            default:
                return <div/>;
        }
    };

    render() {
        return <div className={"List"}>
            {this.props.items.map((i, idx) => <this.Item key={this.key + "_item_" + idx} {...this.props} index={idx} itemStyle={this.props.itemStyle} {...i}/>)}
        </div>
    }
}

export default List;