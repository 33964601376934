import React from "react";
import './styles.css';
import noavatar from "../../../img/noavatar.svg";
import {Link} from "react-router-dom";
import Connection from "../../Connection";

var QRCode = require('qrcode.react');

class Codigo extends React.Component {

    render() {
        let utilizador = window.App.state.utilizador;
        return <div className="Codigo flex">
            <div className={"DetalhesUtilizador container"}>
                <img src={utilizador.imagem ? "" + Connection.getBase() + "/api/imagem/utilizador/" + utilizador.imagem : noavatar} className="avatar"/>
                <h2>{utilizador.nome}</h2>
                <h3>{utilizador.email}</h3>
            </div>
            <QRCode className={"qrcode"} renderAs={"svg"} value={utilizador.email} size={600}/>
            <div className="text-center container">
                <Link to={"/"}>
                    <button className="btn btn-primary" type="submit" style={{marginTop: 20}}>Fechar</button>
                </Link>
            </div>
        </div>
    }
}

export default Codigo;