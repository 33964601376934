import React from "react";
import './styles.css';
import noavatar from "../../../img/noavatar.svg";
import {withRouter} from "react-router-dom";
import List from "../../components/list";
import Connection from "../../Connection";
import Loading from "../../components/loading";
import General from "../../General";

class PerfilEditar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.obterPerfil();
        this.obterFaixasEtarias();
        this.obterInteresses();

        this.utilizador = {};
        console.log(this.props);
    }

    async obterPerfil() {
        let {utilizador, registos} = await Connection.get("utilizador/perfil");
        this.utilizador = {
            nome: utilizador.nome,
            faixa_etaria: utilizador.faixa_etaria && utilizador.faixa_etaria.id,
            instituicao: utilizador.instituicao,
            funcao: utilizador.funcao,
            interesses: utilizador.interesses.map(i => i.id)
        };
        this.setState({utilizador, registos})
    }

    async obterFaixasEtarias() {
        let faixas_etarias = await Connection.get("registo/faixas_etarias");
        this.setState({faixas_etarias})
    }

    async obterInteresses() {
        let interesses = await Connection.get("interesses");
        this.setState({interesses})
    }

    async guardar() {
        let utilizador = this.utilizador;
        await Connection.post("utilizador/editar", utilizador);
        await window.App.checkStatus();
        General.success("Informações alteradas com sucesso");
        this.props.history.push("/perfil")
    }

    render() {
        if (!this.state.utilizador)
            return <Loading/>;

        return <div className="PerfilEditar flex">
            <div className={"container"}>
                <div className={"CabecalhoPerfilEditar page_header_padding"}>
                    <input type="file" style={{display: "none"}} accept="image/*" ref="user_image_input" onChange={() => this.enviarImagem(this.refs.user_image_input)}/>
                    <img src={this.state.utilizador.imagem ? "" + Connection.getBase() + "/api/imagem/utilizador/" + this.state.utilizador.imagem : noavatar} className="avatar"/>
                    <button onClick={() => this.refs.user_image_input.click()} className="btn btn-primary btn-sm" type="submit">Editar Foto</button>
                </div>
                <h3 className={"section-title"}>Alterar Dados</h3>
                <List appearance={"input"} items={[
                    <input onChange={({target}) => {
                        this.utilizador.nome = target.value;
                    }} type={"text"} placeholder={"Nome e Apelido"} defaultValue={this.state.utilizador.nome}/>,
                    <select onChange={({target}) => {
                        this.utilizador.faixa_etaria = parseInt(target.value);
                    }}>
                        <option value={null} disabled={true} selected={true}>Faixa Etária</option>
                        {(this.state.faixas_etarias || []).map((o) => {
                            return <option selected={this.state.utilizador.faixa_etaria && o.id === this.state.utilizador.faixa_etaria.id} value={o.id}>{o.designacao}</option>
                        })}
                    </select>,
                    <input onChange={({target}) => {
                        this.utilizador.instituicao = target.value;
                    }} type={"text"} placeholder={"Instituição"} defaultValue={this.state.utilizador.instituicao}/>,
                    <input onChange={({target}) => {
                        this.utilizador.funcao = target.value;
                    }} type={"text"} placeholder={"Função na instituição"} defaultValue={this.state.utilizador.funcao}/>,
                ]}/>
                <h3 className={"section-title with-instructions"} style={{marginBottom: 0}}>Interesses</h3>
                <p className={"instructions"}>Clique nas opções para ativar ou desativar</p>
                <List appearance={"toggle"} onChange={({target}) => {
                    let value = parseInt(target.value);
                    if (this.utilizador.interesses.find((interesse) => interesse === value)) {
                        this.utilizador.interesses = this.utilizador.interesses.filter((interesse) => interesse !== value);
                    } else {
                        this.utilizador.interesses.push(value);
                    }
                }} items={
                    (this.state.interesses || []).map((interesse) => {
                        return {
                            value: interesse.id,
                            label: interesse.designacao,
                            active: this.utilizador.interesses.findIndex(i => i === interesse.id) > -1
                        };
                    })}/>
                <div className={"text-center"} style={{marginTop: 45}}>
                    <button className="btn btn-primary" onClick={() => this.guardar()}>Guardar</button>
                </div>
            </div>
        </div>
    }

    enviarImagem(input) {
        let files = input.files;
        if (!files[0].type.match('image.*')) {
            General.error("Imagem inválida");
            return
        }

        if (files[0].size > 10485760) {
            General.error("O tamanho máximo de ficheiro é 10MB");
            return;
        }

        var formData = new FormData();
        formData.append('imagem', files[0]);

        Connection.post("utilizador/imagem/editar", formData, async (data) => {
            General.success("Imagem alterada com sucesso.");
            await window.App.checkStatus();
            this.obterPerfil();
        });
    }
}

export default withRouter(PerfilEditar);