import axios from 'axios';
import $ from "jquery";
import General from "./General";
import {messaging} from "../init-fcm";

const _axios = axios.create({withCredentials: true, paramsSerializer: $.param});

const {version: APP_VERSION, homepage, public_home: BASE, api_base: API_BASE} = require("../../package.json");

let firebaseToken = null;
let urlParams = getUrlParameters(window.location.search);

let func = {
    init: function () {
        if (urlParams.firebase_token) {
            firebaseToken = urlParams.firebase_token;
            func.enviarInfoSessao();
        } else
            startFirebaseService();
    },

    logout: function (callback) {
        func.post("logout", null, function (data) {
            callback();
        });
    },

    getBase: function () {
        return BASE;
    },

    post: function (url, params, success, error, options) {
        return new Promise((resolve, reject) => {
            window.App.setLoading();
            _axios.post(API_BASE + url, params, {
                withCredentials: true,
            }).then(res => {
                window.App.stopLoading();
                if (res.headers['content-type'] === "application/json" && typeof res.data === "string") {
                    General.error("Invalid response", "label-error");
                    return;
                }
                if (res.data.message && !(options && options.disableNotif)) {
                    if (res.data.result === "success") {
                        General.success(res.data.message);
                    } else if (res.data.result === "warning") {
                        General.warning(res.data.message);
                    }
                }
                resolve(res.data.data);
                success(res.data.data);
            }).catch(err => {
                window.App.stopLoading();
                if (err.response) {
                    if (err.response.headers['content-type'] === "application/json" && typeof err.response.data === "string") {
                        General.error("Invalid response", "label-error");
                        return;
                    }
                    if (err.response.data.message && !(options && options.disableNotif)) {
                        if (typeof err.response.data.message === 'string')
                            err.response.data.message = [err.response.data.message];
                        err.response.data.message.forEach(function (message, idx) {
                            setTimeout(() => General.error(message), idx * 100);
                        });
                    }

                    if (err.response.status === 401)
                        window.App.logout();
                    if (err.response.data.result === 'redirect')
                        window.location = err.response.data.location;

                    reject(err.response.data, err.response.status);
                    if (error)
                        error(err.response.data, err.response.status);
                }
            });
        });
    },

    get: function (url, params, success, error) {
        checkAppVersion();
        url = API_BASE + url;
        return new Promise((resolve, reject) => {
            _axios.get(url, {withCredentials: true, params: params})
                .then(res => {
                    if (res.headers['content-type'] === "application/json" && typeof res.data === "string") {
                        General.error("Invalid response", "label-error");
                        return;
                    }
                    if (res.data.message)
                        if (res.data.result === "success") {
                            General.success(res.data.message);
                        } else if (res.data.result === "warning") {
                            General.warning(res.data.message);
                        }

                    if (res.data.versao && res.data.versao > window.APP_VERSION)
                        General.alert("Está disponível uma versão da APP mais recente. Por favor recarrega a página.", null, "Atualizar", () => window.location.reload())

                    resolve(res.data.data);
                    if (success)
                        if (res.data.data) {
                            success(res.data.data);
                        } else {
                            success(res.data);
                        }

                }).catch(err => {
                if (err.response) {
                    if (err.response.headers['content-type'] === "application/json" && typeof err.response.data === "string") {
                        General.error("Invalid response", "label-error");
                        return;
                    }
                    if (err.response.data.message) {
                        if (typeof err.response.data.message === 'string')
                            err.response.data.message = [err.response.data.message];
                        err.response.data.message.forEach(function (message, idx) {
                            setTimeout(() => General.error(message), idx * 100);
                        });
                    }

                    if (err.response.data.result === 'redirect') {
                        window.location = err.response.data.location;
                    }

                    if (err.response.data.versao && err.response.data.versao > window.APP_VERSION)
                        General.alert("Está disponível uma versão da APP mais recente. Por favor recarrega a página.", null, "Atualizar", () => window.location.reload())

                    if (err.response.status === 401) {
                        window.App.logout();
                    }

                    reject(err.response.data, err.response.status);
                    if (error)
                        error(err.response.data, err.response.status);

                }
            });
        });
    },

    enviarInfoSessao: function () {
        let info = {platform: "web"};
        if (firebaseToken)
            info['firebase_token'] = firebaseToken;
        if (urlParams.platform)
            info['platform'] = urlParams.platform;

        func.post("utilizador/guardar_info_sessao", info);
    },

    APP_VERSION,
    BASE,
    PLATFORM: urlParams.platform || "web"
};

let nextVersionCheck = 0;

function checkAppVersion() {
    if (nextVersionCheck < +new Date()) {
        fetch(homepage + 'meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                console.log("Local version: " + APP_VERSION, ", Latest Version: " + latestVersion);
                const shouldForceRefresh = semverGreaterThan(latestVersion, APP_VERSION);
                if (shouldForceRefresh) {
                    if (window.confirm("Detetada nova versão da aplicação. Atualizar?")) {
                        if (caches) {
                            caches.keys().then(function (names) {
                                for (let name of names) caches.delete(name);
                            });
                        }
                        window.location.reload(true);
                    } else {
                        return;
                    }
                }
                // Wait 10 minutes before checking again
                nextVersionCheck = 1000 * 60 * 1 + (+new Date());
            });
    }
}

function semverGreaterThan(versionA, versionB) {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);

    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
}

function startFirebaseService() {
    if (typeof Notification !== 'undefined' && Notification.requestPermission) {
        let perm = Notification.requestPermission();
        perm && perm.then(async function () {
            firebaseToken = await messaging.getToken();
            func.enviarInfoSessao();
        }).catch(function (err) {
            console.log("Unable to get permission to notify.", err);
        });
    }
}

function getUrlParameters(url) {
    if (!url) url = window.location.search;
    var query = url.substr(1);
    var result = {};
    query.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
}

export default func;