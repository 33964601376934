import React, {Component} from 'react';
import './styles.css';
import {
    matchPath,
    useLocation,
    Link
} from "react-router-dom";


function Button({page, icon}) {
    let location = useLocation();
    let match = matchPath(location.pathname, {
        path: page,
        exact: true
    });
    return <Link to={page} className={"BottomTabNavigationButton" + (match ? " active" : "")}>{icon}</Link>
}

class BottomTabNavigation extends Component {
    render() {
        return (<div className="BottomTabNavigation">
            {this.props.buttons.map((button) => <Button page={button.page} icon={button.icon}/>)}
        </div>);
    }
}

export default BottomTabNavigation;