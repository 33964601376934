import React from "react";
import './styles.css';
import noavatar from "../../../img/noavatar.svg";
import {Link} from "react-router-dom";
import List from "../../components/list";
import Connection from "../../Connection";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import General from "../../General";

const CabecalhoPerfil = (props) => {
    return <div className={"CabecalhoPerfil page_header_padding"}>
        <img src={props.imagem ? "" + Connection.getBase() + "/api/imagem/utilizador/" + props.imagem : noavatar} className="avatar"/>
        <div className={"col_direita"}>
            <h2>{props.nome}</h2>
            {props.instituicao && <h3>{props.instituicao}<br/>{props.funcao}</h3>}
            {props.faixa_etaria && <p>{props.faixa_etaria.designacao}</p>}
            <Link to={"/perfil/editar"}>
                <button className="btn btn-primary btn-sm" type="submit">Editar Perfil</button>
            </Link>
            <button onClick={window.App.logout} className="btn btn-sm" type="submit" style={{marginLeft: 5}}>Logout</button>
        </div>
    </div>;
};

class Perfil extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.obterPerfil();
    }

    async obterPerfil() {
        let {utilizador, registos} = await Connection.get("utilizador/perfil");
        this.setState({utilizador, registos})
    }

    async obterCertificado(codigo_edicao) {
        window.App.addOverlay(<Modal title={"Certificado de participação"}>
            <div className={"container"}>
                <p>Para obter o certificado da edição <strong>{codigo_edicao}</strong> clique no seguinte botão:</p>
                <div className={"text-center"} style={{marginTop: 20}}>
                    <button className="btn btn-primary" onClick={() => window.open(Connection.getBase() + "app/utilizador/certificado/obter/" + codigo_edicao)}>Descarregar Certificado</button>
                </div>
            </div>
        </Modal>)
    }

    render() {
        if (!this.state.utilizador)
            return <Loading/>;

        let primeiro_registo = this.state.registos.length > 0 && !this.state.registos[0].edicao.terminou ? this.state.registos[0] : null;
        let registos_anteriores = primeiro_registo ? this.state.registos.slice(1) : this.state.registos;

        return <div className="Perfil flex">
            <div className={"container"}>
                <CabecalhoPerfil {...this.state.utilizador}/>
                {this.state.utilizador.interesses.length > 0 && [
                    <h3 className={"section-title"}>Os meus interesses</h3>,
                    <div className={"interesses"}>
                        {this.state.utilizador.interesses.map(interesse => {
                            return <span className={"interesse"}>{interesse.designacao}</span>;
                        })}
                    </div>
                ]}
                {primeiro_registo && <div>
                    <h3 className={"section-title"} style={{textTransform: "uppercase", fontWeight: "bold", marginBottom: 0}}>{primeiro_registo.edicao.titulo}</h3>
                    <List appearance={"session"} items={primeiro_registo.sessoes.map((sessao) => {
                        return {title: sessao.titulo, speakers: sessao.oradores.map((orador) => orador.nome).join(" / "), local: sessao.local && sessao.local.designacao}
                    })}/>
                    {primeiro_registo.detalhes.certificado && <div className={"text-center"} style={{marginTop: 20}}>
                        <button className="btn btn-primary" onClick={() => this.obterCertificado(primeiro_registo.edicao.codigo)}>Obter Certificado</button>
                    </div>}
                </div>}
                {registos_anteriores.length > 0 && [
                    <h3 className={"section-title"}>Edições Anteriores</h3>,
                    <List appearance={"accordion"} items={[
                        ...registos_anteriores.map((registo, idx) => {
                            return {
                                title: registo.edicao.titulo,
                                innerTitle: <div>
                                    {registo.dias.length > 0 && <p style={{marginBottom: 15}}>Participou nos dia{registo.dias.length > 1 ? "s" : ''} {General.datasParaTexto(registo.dias)}</p>}
                                    <div className={"text-right"}>
                                        <button onClick={() => this.obterCertificado(registo.edicao.codigo)} className="btn btn-primary btn-sm">Obter Certificado</button>
                                    </div>
                                </div>,
                                content: <List appearance={"session"} itemStyle={{padding: 15}} items={registo.sessoes.map((sessao) => {
                                    return {title: sessao.titulo, speakers: sessao.oradores.map((orador) => orador.nome).join(" / "), local: sessao.local && sessao.local.designacao}
                                })}/>,
                                expanded: idx === 0 && !primeiro_registo
                            };
                        })
                    ]}/>
                ]}
            </div>
        </div>
    }
}

export default Perfil;