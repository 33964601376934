import React, {Component} from 'react';
import DesbloquearDesafio from "./pages/desbloquear_desafio";
import Programa from "./pages/programa";
import Home from "./pages/home";
import Splashscreen from "./components/splashscreen";
import Connection from "./Connection";
import {NotificationContainer} from 'react-notifications';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import Login from "./pages/login";
import BottomTabNavigation from "./components/bottom_tab_navigation"
import {Redirect, Route, Router, Switch, withRouter,} from "react-router-dom";
import {createBrowserHistory} from 'history';
import $ from "jquery";

import IosHomeOutline from "react-ionicons/lib/IosHomeOutline";
import IosList from "react-ionicons/lib/IosList";
import IosInformationCircleOutline from "react-ionicons/lib/IosInformationCircleOutline";
import Codigo from "./pages/codigo";
import Perfil from "./pages/perfil";
import PerfilEditar from "./pages/perfil_editar";
import Sessao from "./pages/sessao";
import Map from "./components/map";
import Info from "./pages/info";

import Notificacoes from "./pages/notificacoes";
import Equipa from "./pages/equipa";
import Galeria from "./pages/galeria";

const RouterSessao = withRouter(Sessao);

const homepage = require("../../package.json").homepage;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            utilizador: null,
            loading: false,
            mapAddress: null,
            withMap: false
        };

        this.overlays = [];

        window.App = this;

        this.logout = this.logout.bind(this);

        window.onhashchange = () => {
            let page = window.location.hash.substring(1);
            if (page === 'logout')
                this.logout();
        };

        this.addOverlay = this.addOverlay.bind(this);
        this.removeOverlay = this.removeOverlay.bind(this);

        this.setLoading = () => {
            this.setState({loading: true});
        };

        this.stopLoading = () => {
            this.setState({loading: false});
        };

        this.history = createBrowserHistory({
            basename: homepage,
            getUserConfirmation: (message, callback) => {
                if (!this.hasMap() && this.overlays.length === 0)
                    return callback(true);

                if (this.hasMap())
                    this.hideMap();
                else
                    this.removeOverlay();

                this.history.goForward();
                return callback(false);
            }
        });
        this.history.block("");

        Connection.init();

        setTimeout(this.checkStatus, 500);
    }

    checkStatus = async () => {
        try {
            let data = await Connection.get("status");
            this.setState({
                utilizador: data.utilizador,
                ready: true
            });
        } catch (e) {
            this.setState({
                ready: true
            })
        }
    };

    addOverlay(overlay) {
        let overlays = [...this.overlays];
        overlays.push(overlay);
        this.overlays = overlays;
        this.forceUpdate();
    }

    removeOverlay(overlay) {
        this.openFormId = null;
        if (!overlay) {
            this.overlays.pop();
        } else {
            let overlays = this.overlays.slice();
            overlays.splice(overlays.indexOf(overlay), 1);
            this.overlays = [...overlays];
        }
        this.forceUpdate();
    }

    logout() {
        let _this = this;
        if (this.state.utilizador) {
            Connection.logout(() => {
                _this.setState({
                    utilizador: null
                });
                window.location.hash = "";
            });
        }
    }

    showMap(address) {
        this.setState({mapAddress: address, withMap: true});
        $(".RouteWrapper").animate({scrollTop: 0}, 300);
    }

    hideMap() {
        this.setState({withMap: false});
    }

    toggleMap(address) {
        let new_state = {withMap: !this.state.withMap};
        if (!this.state.withMap) {
            new_state.mapAddress = address;
            $(".RouteWrapper").animate({scrollTop: 0}, 300);
        }

        this.setState(new_state);
    }

    hasMap() {
        return this.state.withMap;
    }

    render() {
        let app = null;

        if (this.state.ready) {
            if (this.state.utilizador)
                app = <Router history={this.history}>
                    <Route render={({location}) => {
                        return <Switch key={location.key} location={location}>
                            <Redirect exact from="/" to="/inicio"/>
                            <Route path="/inicio">
                                <div className="RouteWrapper see-through">
                                    <Home/>
                                </div>
                            </Route>
                            <Route path="/equipa">
                                <div className="RouteWrapper">
                                    <Equipa/>
                                </div>
                            </Route>
                            <Route path="/programa" exact={true}>
                                <div className="RouteWrapper">
                                    <Programa/>
                                </div>
                            </Route>
                            <Route path="/sessao/:id">
                                <div className="RouteWrapper">
                                    <RouterSessao/>
                                </div>
                            </Route>
                            <Route path="/desafio/desbloquear" exact={true}>
                                <div className="RouteWrapper">
                                    <DesbloquearDesafio/>
                                </div>
                            </Route>
                            <Route path="/codigo">
                                <div className="RouteWrapper">
                                    <Codigo/>
                                </div>
                            </Route>
                            <Route exact={true} path="/perfil">
                                <div className="RouteWrapper">
                                    <Perfil/>
                                </div>
                            </Route>
                            <Route exact={true} path="/perfil/editar">
                                <div className="RouteWrapper">
                                    <PerfilEditar/>
                                </div>
                            </Route>
                            <Route exact={true} path="/galeria">
                                <div className="RouteWrapper">
                                    <Galeria/>
                                </div>
                            </Route>
                            <Route exact={true} path="/info">
                                <div className="RouteWrapper">
                                    <Info/>
                                </div>
                            </Route>
                            <Route exact={true} path="/notificacoes">
                                <div className="RouteWrapper">
                                    <Notificacoes/>
                                </div>
                            </Route>
                        </Switch>
                    }}/>
                    <BottomTabNavigation buttons={[
                        {page: "/inicio", icon: <IosHomeOutline/>},
                        //{page: "/equipa", icon: <IosPeopleOutline/>},
                        {page: "/programa", icon: <IosList/>},
                        //{page: "/galeria", icon: <IosImagesOutline/>},
                        {page: "/info", icon: <IosInformationCircleOutline/>}
                    ]}/>
                </Router>
            else {
                app = <Login/>;
            }
        } else {
            app = (<Splashscreen/>);
        }

        if (this.overlays.length > 0) {
            disableBodyScroll(null);
        } else {
            clearAllBodyScrollLocks();
        }
        return (<div className={Connection.PLATFORM}>
            <div className={"flex" + (this.overlays.length > 0 ? " hasOverlay" : "") + (this.state.withMap ? " withMap" : "")}>
                {this.state.utilizador && <Map address={this.state.mapAddress}/>}
                {app}
            </div>
            <div id={"app_overlays"}>
                {this.overlays}
            </div>
            <NotificationContainer/>
            {this.state.loading && <span className="app-loading"><span/></span>}
        </div>)
    }
}

export default App;
